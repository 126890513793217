.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 50%;
        max-height: 90%;
    }
    
    h1 { 
        font-size: 53px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;  

        img {
            width: 32px;
        }
    }

    h2 {
        color: #52796F;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 2px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #2F3E46;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #2F3E46;
        border-radius: 7px;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background-color: #2F3E46;
            color: #CAD2C5;
        }

    }

}